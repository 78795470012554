import React from 'react'
import Header from './Header'
import Footer from './Footer'

function PrivacyPolicy() {
  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="section-title">Privacy Policy</h1>
              <p>
                The website <a href="https://https://safaixyz.com/">https://safaixyz.com/</a> and its mobile application (each a “Platform”) enables sellers to list and offer to sell their products to various buyers without any kind of intervention from SafaiXYZ. SafaiXYZ recognizes the importance of privacy as well as the importance of maintaining the confidentiality of personal information. This Privacy Policy (“Privacy Policy”) applies to all products and services provided by SafaiXYZ and sets out how SafaiXYZ may collect, use and disclose information in relation to User(s) of the Platform.
              </p>
              <p>
                User(s) may use SafaiXYZ's services and products via a mobile device either through mobile applications or mobile optimized websites. This Privacy Policy also applies to such use of SafaiXYZ's services and products.
              </p>
              <p>
                All capitalized terms not defined in this document shall have the meanings ascribed to them in the Terms of Use of the Platform, which can be found <a href="#">here</a>. Contracting entity shall be SafaiXYZ.
              </p>

              <h2>1. COLLECTION OF INFORMATION</h2>
              <ol>
                <li>User(s) privacy is important to SafaiXYZ and SafaiXYZ have taken steps to ensure that SafaiXYZ do not collect more information from User than is necessary for SafaiXYZ to provide User(s) with SafaiXYZ's services and to protect User(s) account.</li>
                <li>Information including, but not limited to, User(s) name, address, phone number, fax number, email address, gender, date and/or year of birth and user preferences ("Registration Information") may be collected at the time of User registration on the Platform.</li>
                <li>In connection with any communication or transaction and payment services or any other services that you may avail using the Platform, information, including but not limited to, bank account numbers, billing and delivery information, credit/debit card numbers and expiration dates and tracking information from cheques or money orders ("Account Information") may be collected, among other things, to facilitate the sale and purchase as well as the settlement of purchase price of the products or services transacted on or procured through the Platform, if applicable.</li>
                <li>SafaiXYZ records and retains details of Users’ activities on the Platform. Information relating to communication or transactions including, but not limited to, the types and specifications of the goods, pricing and delivery information, any dispute records and any information disclosed in any communication forum provided by us and/or other affiliated companies of SafaiXYZ (“Activities Information”) may be collected as and when the communication and/or transactions are conducted through the Platform.</li>
                <li>SafaiXYZ records and retains records of Users’ browsing or buying activities on Platform including but not limited to IP addresses, browsing patterns and User behavioural patterns. In addition, we gather statistical information about the Platform and visitors to the Platform including, but not limited to, IP addresses, browser software, operating system, software and hardware attributes, pages viewed, number of sessions and unique visitors (together "Browsing Information").</li>
                <li>Registration Information, Account Information, Activities Information, and Browsing Information are collectively referred to as User Data.</li>
                <li>It is mandatory for Users of the Platform to provide certain categories of User Data (as specified at the time of collection). In the event that Users do not provide any or sufficient User Data marked as mandatory, SafaiXYZ may not be able to complete the registration process or provide such Users with SafaiXYZ's products or services.</li>
              </ol>

              <h2>2. USE OF USER DATA</h2>
              <p>
                If you provide any User Data to SafaiXYZ, you are deemed to have authorized SafaiXYZ to collect, retain and use that User Data for the following purposes:
              </p>
              <ol>
                <li>Verification of User’s identity;</li>
                <li>Processing User’s registration as a user, providing User(s) with a log-in ID for the Platform and maintaining and managing User’s registration;</li>
                <li>Providing User(s) with customer service and responding to User(s) queries, feedback, claims or disputes;</li>
                <li>To enable communication between Users on the Platform and/or processing Users transactions on the Platform;</li>
                <li>Performing research or statistical analysis in order to improve the content and layout of the Platform, to improve SafaiXYZ's product offerings and services and for marketing and promotional purposes;</li>
                <li>Subject to applicable laws, SafaiXYZ (including our affiliated companies and their designated Service Providers) may use User’s name, phone number, residential address, email address, fax number and other data ("Marketing Data") to provide notices, surveys, product alerts, communications and other marketing materials to User(s) relating to products and services offered by SafaiXYZ or SafaiXYZ's affiliated companies;</li>
                <li>If User(s) voluntarily submit any User(s) information or other information to the Platform for publication on the Platform through the publishing tools, then User(s) are deemed to have given consent to the publication of such information on the Platform; and</li>
                <li>Making such disclosures as may be required for any of the above purposes or as required by law, regulations, and guidelines or in respect of any investigations, claims or potential claims brought on or against us or against third parties.</li>
              </ol>

              <h2>3. DISCLOSURE OF USER DATA</h2>
              <ol>
                <li>User(s) further agrees that SafaiXYZ may disclose and transfer User(s) Data to third party service providers (including but not limited to data entry, database management, promotions, products and services alerts, delivery services, payment extension services, authentication and verification services and logistics services) ("Service Providers"). These Service Providers are under a duty of confidentiality to SafaiXYZ and are only permitted to use User(s) Data in connection with the purposes specified in clause 2 herein above.</li>
                <li>User(s) agree that SafaiXYZ may disclose and transfer User(s) Data to SafaiXYZ's affiliated companies and/or their designated Service Providers.</li>
                <li>When necessary SafaiXYZ may also disclose and transfer User(s) Data to our professional advisers, law enforcement agencies, insurers, government and regulatory and other organizations.</li>
                <li>Any User(s) Data supplied by User(s) will be retained by SafaiXYZ and will be accessible by our employees, any Service Providers engaged by SafaiXYZ and third parties referred to in clause 3 herein, for or in relation to any of the purposes stated in Clause 2 herein above.</li>
                <li>All voluntary information provided/uploaded such as products, images, remarks, feedbacks etc. by User(s) on the Platform may be made publicly available on the Platform and therefore accessible by any internet user. Any voluntary information that User disclose to SafaiXYZ becomes public information and User relinquishes any proprietary rights in such information. User(s) should exercise caution when deciding to include personal or proprietary information in the voluntary information that User(s) submits to SafaiXYZ or uploads on the Platform.</li>
                <li>SafaiXYZ may share User Data with third parties, including without limitation, banks, financial institutions, credit agencies, or vendors to enable such third parties to offer their products or services to such Users. While SafaiXYZ shall endeavour to have in place internal procedures to keep User Data secure from intruders, there is no guarantee that such measures/procedures can eliminate all of the risks of theft, loss or misuse.</li>
                <li>SafaiXYZ may establish relationships with other parties and websites to offer User the benefit of products and services which SafaiXYZ does not offer. SafaiXYZ may offer you access to these other parties and/or their websites. This Privacy Policy does not apply to the products and services enabled or facilitated by such third parties. The privacy policies of those other parties may differ from SafaiXYZ, and SafaiXYZ has no control over the information that User may submit to those third parties. User should read the relevant privacy policy for those third parties before responding to and availing any offers, products or services advertised or provided by those third parties.</li>
              </ol>

              <h2>4. RIGHT TO UPDATE USER DATA</h2>
              <p>
                Under the applicable laws, User(s) have the right of access to personal information held by SafaiXYZ and to request updating/correcting the information.
              </p>

              <h2>5. COOKIES</h2>
              <p>
                SafaiXYZ uses "cookies" to store specific information about User(s) and track User(s) visits to the Sites. A "cookie" is a small amount of data that is sent to User’s browser and stored on User’s device. If User does not deactivate or erase the cookie, each time User uses the same device to access the Platform, our services will be notified of User visit to the Platform and in turn SafaiXYZ may have knowledge of User(s) visit and the pattern of User’s usage.
              </p>
              <p>
                Generally, SafaiXYZ use cookies to identify User(s) and enable SafaiXYZ to:
              </p>
              <ol>
                <li>Access User’s Registration Information or Account Information so User(s) do not have to re-enter it;</li>
                <li>Gather statistical information about usage by Users(s);</li>
                <li>Research visiting patterns and help target advertisements based on User(s) interests;</li>
                <li>Assist SafaiXYZ's partners to track User(s) visits to the Platform and process orders; and</li>
                <li>Track progress and participation on the Platform.</li>
              </ol>
              <p>
                User(s) can determine if and how a cookie will be accepted by configuring the browser which is installed in User’s device. If User(s) chooses, User(s) can change those configurations. If User(s) reject all cookies by choosing the cookie-disabling function, User may be required to re-enter information on the Platform more often and certain features of the Platform may be unavailable.
              </p>

              <h2>6. MINORS</h2>
              <p>
                The Platform and its contents are not targeted to minors (those under the age of 18). However, SafaiXYZ has no way of distinguishing the age of individuals who access our Platform. If a minor has provided SafaiXYZ with personal information without parental or guardian consent, the parent or guardian should contact SafaiXYZ's Legal Department at the address set out in clause 11 below to remove the information.
              </p>

              <h2>7. SECURITY MEASURES</h2>
              <p>
                SafaiXYZ employs commercially reasonable security methods to prevent unauthorized access to the Platform, to maintain data accuracy and to ensure the correct use of the information SafaiXYZ holds. No data transmission over the internet or any wireless network can be guaranteed to be perfectly secure. As a result, while SafaiXYZ tries to protect the information SafaiXYZ holds, SafaiXYZ cannot guarantee the security of any information the User transmits to SafaiXYZ and User(s) do so at their own risk.
              </p>

              <h2>8. DATA RETENTION</h2>
              <p>
                SafaiXYZ takes every reasonable step to ensure that User’s personal information is processed ‘as is’. You may access, correct, and update your personal information directly through the functionalities provided on the Platform.
              </p>
              <p>
                You have an option to withdraw your consent that you have already provided by writing to us at the contact information provided below. Please mention “for withdrawal of consent” in the subject line of your communication. SafaiXYZ will verify such requests before acting upon your request. Please note, however, that withdrawal of consent will not be retroactive and will be in accordance with the terms of this Privacy Policy, related terms of use, and applicable laws. In the event you withdraw the consent given to us under this Privacy Policy, such withdrawal may hamper your access to the Platform or restrict the provision of our services to you for which SafaiXYZ consider that information to be necessary.
              </p>

              <h2>9. USER'S RIGHTS</h2>
              <p>
                SafaiXYZ retains your personal information in accordance with applicable laws, for a period no longer than is required for the purpose for which it was collected or as required under any applicable law. However, SafaiXYZ may retain data related to you if it believes it may be necessary to prevent fraud or future abuse, to enable SafaiXYZ to exercise its legal rights and/or defend against legal claims or if required by law or for other legitimate purposes. SafaiXYZ may continue to retain your data in anonymised form for analytical and research purposes.
              </p>

              <h2>10. CHANGES TO THIS PRIVACY POLICY</h2>
              <p>
                Any changes to this Privacy Policy will be communicated by us posting an amended and restated Privacy Policy on the Platform. Once posted on the Platform the new Privacy Policy will be effective immediately. Your continued use of the Platform shall be deemed to be your acceptance to the provisions of the Privacy Policy. User(s) agrees that any information SafaiXYZ hold about User (as described in this Privacy Policy and whether or not collected prior to or after the new Privacy Policy became effective) will be governed by the latest version of the Privacy Policy.
              </p>

              <h2>11. GRIEVANCE OFFICER</h2>
              <p>
                In accordance with Information Technology Act 2000 and rules made there under, the name and contact details of the Grievance Officer are provided below:
              </p>
              <p>
                Noida Sector 63, 201301<br />
                Phone: +91-8006305606<br />
                Email: <a href="mailto:helpdesk@safaixyz.com">helpdesk@safaixyz.com</a><br />
                Time: 24*7
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default PrivacyPolicy